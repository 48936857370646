import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/connect',
    name: 'Connect',
    component: () => import('../views/Connect.vue')
  },
  {
    path: '/rw-mobile',
    name: 'Emitron',
    component: () => import('../views/_projects/Emitron.vue')
  },
  {
    path: '/rw-onboarding',
    name: 'Onboarding',
    component: () => import('../views/_projects/Onboarding.vue')
  },
  {
    path: '/kdo',
    name: 'Kodeco',
    component: () => import('../views/_projects/Kodeco.vue')
  },
  {
    path: '/kdo-enterprise',
    name: 'KodecoEnterprise',
    component: () => import('../views/_projects/KodecoEnterprise.vue')
  },
  {
    path: '/icm',
    name: 'ICM',
    component: () => import('../views/_projects/ICM.vue')
  },
  {
    path: '/mobile',
    name: 'IDCC',
    component: () => import('../views/_projects/IDCC.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue')
  }
  // Define more routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
})

export default router
