import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from "@unhead/vue"

const app = createApp(App)
const head = createHead()

app.directive('lazy', {
  mounted(el, binding) {
    const options = {
      rootMargin: '1000px',
      threshold: 0
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = new Image()
          img.src = binding.value
          img.onload = () => {
            el.src = img.src
            observer.unobserve(el)
            el.classList.add('lazy-loaded')
          }
        }
      })
    }, options)
    observer.observe(el)
  }
})

app.use(router)
app.use(head)
app.mount('#app')
