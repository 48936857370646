<template>
  <div>
    <div class="mw-1380 mt-100 mx-auto">
      <div>
        <h1 class="fs-80 mobile-fs-36 medium lh-1 lh-1-2">Senior Product Designer <span class="desktop-block-mobile-inline">and Front-end Developer.</span></h1>
        <p class="fs-24 mobile-fs-20 mt-20">Background in digital product design and front-end development. I create digital products and experiences that are practical, functional and creative. Delivered designs to millions of users through work at Deel, Kodeco, raywenderlich.com and People Connect.</p>
      </div>
    </div>
    <div class="mw-1380 mt-100 mx-auto grid-2 grid-col-gap-60 tablet-grid-row-gap-80">
      <div class="flex gap-80 mt-80 tablet-mt-0 mw-100">
        
        <div class="c-project-cover">
          <router-link to="/kdo" exact></router-link>
          <div class="c-project-cover__artwork c-project-cover__artwork--video c-project-cover__artwork--left">
            <video autoplay muted loop playsinline>
              <source src="@/assets/videos/kodeco-showreel.mp4" type="video/mp4" />
            </video>
          </div>
          <span><span class="medium">Kodeco</span> &middot; 2022 &middot; UI, Design System, Accessibility</span>
          <h2>Creating a user friendly and scalable design system for Kodeco</h2>
        </div>
        
        <div class="c-project-cover">
          <router-link to="/rw-mobile" exact></router-link>
          <div class="c-project-cover__artwork c-project-cover__artwork--video bg-grad-pink--tablet">
            <video autoplay muted loop playsinline>
              <source src="@/assets/videos/emitron-showreel.mp4" type="video/mp4" />
            </video>
          </div>
          <span><span class="medium">raywenderlich.com</span> &middot; 2019 &middot; UX(UI), User Interviews, Usability Testing</span>
          <h2>Designing an app for raywenderlich.com subscribers so they can learn offline with the best mobile development tutorials</h2>
        </div>
        
        <div class="c-project-cover hidden--tablet">
          <router-link to="/icm" exact></router-link>
          <div class="c-project-cover__artwork">
            <img v-lazy="require('@/assets/images/projects/people/people-mockup-001@1.5x.webp')" alt="">
          </div>
          <span><span class="medium">People Connect</span> &middot; 2016 &middot; UI design, Front-end development, Split testing</span>
          <h2 class="">Optimizing sales funnels through split testing at People Connect</h2>
        </div>
      </div>
      <div class="flex gap-80 mw-100">

        <div class="c-project-cover">
          <router-link to="/kdo-enterprise" exact></router-link>
          <div class="c-project-cover__artwork c-project-cover__artwork--video">
            <video autoplay muted loop playsinline>
                <source src="@/assets/videos/kodeco-ent-showreel.mp4" type="video/mp4" />
            </video>
          </div>
          <span><span class="medium">Kodeco</span> &middot; 2022 &middot; UI (Mobile), UX (Interviews, Testing)</span>
          <h2>Designing features that drive enterprise growth and retention for Kodeco</h2>
        </div>
        
        <div class="c-project-cover">
          <router-link to="/rw-onboarding" exact></router-link>
          <div class="c-project-cover__artwork c-project-cover__artwork--video c-project-cover__artwork--left">
            <video autoplay muted loop playsinline>
                <source src="@/assets/videos/onboarding-lottie-select.mp4" type="video/mp4" />
            </video>
          </div>
          <span><span class="medium">raywenderlich.com</span> &middot; 2021 &middot; User research, UI design, Prototyping</span>
          <h2>Designing a more engaging and effective onboarding experience for raywenderlich.com</h2>
        </div>
        
        <div class="c-project-cover hidden show--tablet">
          <router-link to="/icm" exact></router-link>
          <div class="c-project-cover__artwork">
            <img v-lazy="require('@/assets/images/projects/people/people-mockup-001@1.5x.webp')" alt="">
          </div>
          <span><span class="medium">People Connect</span> &middot; 2016 &middot; UI design, Front-end development, Split testing</span>
          <h2>Optimizing sales funnels through split testing at People Connect</h2>
        </div>
        
        <div class="c-project-cover">
          <router-link to="/mobile" exact></router-link>
          <div class="c-project-cover__artwork">
            <img v-lazy="require('@/assets/images/projects/mobile/mobile-mockup-000@1.5x.webp')" alt="">
          </div>
          <span><span class="medium">IDCC</span> &middot; 2015 &middot; UI (mobile), Prototyping (Framer)</span>
          <h2>Designing mobile applications for IDCC in health, insurance, gaming, travel and sports</h2>
        </div>
      </div>
    </div>

    <div class="mw-1380 mt-100 mx-auto grid-2 grid-gap-60" id="#projects">
      <div>
        <h1 class="fs-80 mobile-fs-36 medium lh-1 lh-1-2">Projects.</h1>
        <p class="fs-24 mobile-fs-20 mt-20">Side projects I've worked on since 2019.</p>
      </div>
    </div>
    <div class="mw-1380 mt-100 mx-auto grid-2 grid-col-gap-60 tablet-grid-row-gap-80">
      <div class="flex gap-80 mw-100">
        <div class="c-project-cover">
          <a href="https://www.coffeelobo.com/" target="_blank"></a>
          <div class="c-project-cover__artwork c-project-cover__artwork--square">
            <img v-lazy="require('@/assets/images/projects/other/coffee-mockup-00@1.5x.webp')" alt="">
          </div>
          <span><span class="medium">Side Project</span> &middot; 2022</span>
          <h2>CoffeeLobo, a collection of speciality coffee shops built with Ruby on Rails</h2>
        </div>
      </div>
      <div class="flex gap-80 mt-80 tablet-mt-0 mw-100">
        <div class="c-project-cover">
          <a href="https://www.androidelements.com/" target="_blank"></a>
          <div class="c-project-cover__artwork c-project-cover__artwork--square">
            <img v-lazy="require('@/assets/images/projects/other/android-mockup-00@1.5x.webp')" alt="">
          </div>
          <span><span class="medium">Side Project</span> &middot; 2019</span>
          <h2>Android Table of Elements, a roadmap for Android developers built with Vue.js</h2>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
}
</script>
