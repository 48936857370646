<template>
  <nav id="nav" class="c-navigation mw-1380 mt-40 mx-auto">
    <router-link to="/" exact><span>LUKE FREEMAN</span></router-link>
    <router-link to="/" exact><span>Work</span></router-link>
    <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>
    <router-link to="/about" exact><span>About</span></router-link>
    <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>
    <a href="./luke-freeman-resume.pdf" target="_blank"><span>Resume</span></a>
    <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>
     <router-link to="/connect" exact><span>Connect</span></router-link>
     <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>
     <a @click="toggleDarkTheme" style="cursor: pointer;"><span>{{ isDarkTheme ? 'Light' : 'Dark' }}</span></a>
  </nav>
</template>

<script>

export default {
  name: 'Navigation',
  data() {
    return {
      isDarkTheme: false
    };
  },
  computed: {
    htmlClasses() {
      return {
        'dark-theme': this.isDarkTheme
      };
    }
  },
  watch: {
    htmlClasses: {
      handler(classes) {
        document.querySelector('html').className = Object.keys(classes).filter(key => classes[key]).join(' ');
      },
      immediate: true
    }
  },
  created() {
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.isDarkTheme = prefersDarkMode;
  },
  methods: {
    toggleDarkTheme() {
      this.isDarkTheme = !this.isDarkTheme;
    }
  }
}
</script>