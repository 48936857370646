<template>
  <div id="app">
    <Navigation />
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <div class="mt-200 mb-40 c-footer">
      <Navigation />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useHead } from '@unhead/vue'
import Navigation from '@/components/Navigation.vue'

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
  },
  setup() {
    useHead({
      title: 'Luke Freeman — Digital Product Design UX(UI)',
      meta: [
        { name: 'description', content: 'Designing digital products and experiences that help people and teams learn, grow and succeed in their goals.' },
        { property: 'og:title', content: 'Luke Freeman — Digital Product Design UX(UI)' },
        { property: 'og:site_name', content: 'Epiloge' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex' }
      ]
    })

    return {}
  }
})
</script>

<style lang="scss">

@import 'src/assets/styles/global.scss';

/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.route-enter-active {
  transition: all .5s ease-out; 
}
.route-leave-to {
  opacity: 0;
  // transform: translateY(10px);
}
.route-leave-active {
  transition: all .25s ease-in; 
}

</style>
